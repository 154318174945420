@import '../../globals.scss';

.ressources-list{
  @extend .global-page;

  .ressource-recap{
    height: 65px;
    border-bottom: 1px solid $white;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__infos{
      display: flex;
      flex-direction: row;
      border-right: 1px solid $white;
      width: 80%;
      justify-content: space-between;

      &__item{
        margin-right: 30px;

        p{
          margin: 0;
        }
      }
    }

    &__actions{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 20%;

      .action-button{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        background-color: $black;
        border: 1px solid $white;
        border-radius: 5px;
        margin-left: 20px;

        &.red{
          background-color: red;
        }
      }
    }
  }
}