@import '../../../globals.scss';

.categories-list-page{
  height: auto;
  min-height: 100svh;
  padding-bottom: 5vh;
  padding-top: 5vh;

  .list{
    margin-top: 10vh;
    max-height: 45vh;
    overflow: auto;
  }
}