@import '../../globals.scss';
@import '../../pages/ressources/ressourceDetails.scss';

.custom-input{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba($white, .5);
  margin-bottom: 10px;
  width: 100%;

  &::placeholder{
    // text-transform: uppercase;
    font-weight: 700;
  }
}

.custom-select{
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 8px 10px 5px 10px;
  outline: none;
  border: none;
  border: 1px solid rgba($white, .5);
  margin-bottom: 20px;
  width: 100%;
  color: $white;

  &::placeholder{
    font-weight: 700;
  }

  option{
    color: $black;
  }
}

.dashboard-add-image {
  @extend .global-page;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
    flex-direction: column;
  }

  &__content{
    display: flex;
    flex-direction: row;

    .left{
      width: 40%;

      .additionnal-property{
        .title{
          font-weight: bold;
          font-size: 1.2rem;
        }
      }

      .property-input-container{
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        align-items: flex-end;

        button{
          text-transform: uppercase;
          cursor: pointer;
          border: 1px solid $white;
          padding: 8px 10px 5px 10px;
          background-color: $black;
          margin-left: 20px;
          width: 40%;
          font-size: .8rem;

          .loader{
            border-width: 2px;
            width: 10px;
            height: 10px;
          }

          &:hover{
            background-color: $white;
            color: $black;

            .loader{
              border-color: $black;
              border-bottom-color: transparent;
            }
          }
        }
      }

      .added-properties{
        border: 2px dashed #555;
        min-height: 8vh;
        max-height: 25vh;
        overflow: auto;
        padding: 5px 10px 5px 10px;
      }

      .submit-button{
        margin-top: 5vh;
        text-transform: uppercase;
        cursor: pointer;
        border: 1px solid $white;
        padding: 8px 10px 5px 10px;
        background-color: $black;
        width: 100%;

        .loader{
          border-width: 2px;
          width: 10px;
          height: 10px;
        }

        &:hover{
          background-color: $white;
          color: $black;

          .loader{
            border-color: $black;
            border-bottom-color: transparent;
          }
        }
      }
    }
    
    .right{
      width: 60%;
      padding-left: 60px;
      
      .drop-container {
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 20px;
        border-radius: 10px;
        border: 2px dashed #555;
        color: #444;
        // cursor: pointer;
        
        &:hover {
          background: #111;
        }
        
        input[type=file]::file-selector-button {
          margin-right: 20px;
          border: 1px solid $white;
          background: transparent;
          color: $white;
          padding: 10px 20px;
          cursor: pointer;
          transition: background .2s ease-in-out;
          text-transform: uppercase;
        }

        input[type=file]::file-selector-button:hover {
          background: $white;
          color: $black;
        }

        .image-preview{
          position: relative;

          .image{
            max-height: 40vh;
            max-width: 100%;
            // margin-top: 10vh;
          }

          button{
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            background-color: red;
            border: none;
            outline: none;
            cursor: pointer;
            color: $white;
          }
          
        }
      }

      .drop-title {
        color: $white;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        transition: color .2s ease-in-out;
      }
    }
  }
}