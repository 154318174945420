@import '../globals.scss';

.mobile-app{
  @extend .global-page;

  .title{
    font-size: 65px;
    text-transform: uppercase;
    font-family: 'Gilroy';
    font-weight: 900;
    text-align: center;

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      font-size: 48px;
    }
  }

  .subtitle{
    font-size: 25px;
    font-family: 'AuxMono';
    font-weight: 500;
    text-align: center;
    letter-spacing: 5px;
    opacity: .5;

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      font-size: 20px;
    }
  }

  .tease{
    font-size: 45px;
    text-transform: uppercase;
    font-family: 'Gilroy';
    font-weight: 900;
    text-align: center;
    margin-top: 10vh;

    @media screen and (max-width: map-get($breakpoints, 'tablet-min')) {
      font-size: 48px;
    }
  }

  .image-container{
    margin-top: 10vh;
    margin-bottom: 10vh;

    img{
      max-width: 100%;
    }
  }

}