@import '../../globals.scss';

.dashboard-list-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $white;
  color: $white;
  padding-top: 10px;
  padding-bottom: 10px;

  &__properties{
    display: flex;
    flex-direction: row;
    justify-content: space-start;
    flex: 1;
    gap: 70px;
    padding-right: 200px;

    &__property{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &__label{
        color: $white;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
      }

      &__value{
        color: $white;
        font-size: 20px;
        margin: 0;  
      }
    }
  }

  &__actions{
    border-left: 1px solid $white;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    &__action{
      width: 30px;
      height: 30px;
      border: none;
      outline: none;
      background-color: rgba($white, .2);
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:hover{
        background-color: rgba($white, .5);
      }


      &--red{
        background-color: $red;

        &:hover{
          background-color: rgba($red, .5);
        }
      }
    }
  }
}