@import '../globals.scss';

// .ressource-category{
//   text-decoration: none;
//   font-size: 1.5rem;
//   border-left: 2px solid transparent;
//   border-bottom: 2px solid transparent;
//   border-bottom-left-radius: 20px;
//   border-top-right-radius: 20px;
//   padding: 0 0 10px 20px;
//   margin-top: 2vh;
//   width: 350px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   border-top: 2px solid transparent;
//   border-right: 2px solid transparent;

//   img{
//     max-height: 4vw;
//     margin-right: 20px;
//   }

//   .subtitle{
//     color: $white;
//     margin: 0;
//   }

//   &:hover{
//     color: $black;
//     // border: 2px solid $white;
//     border-left: 2px solid $white;
//      border-bottom: 2px solid $white;
//   }
// }

.ressource-category{
  text-decoration: none;
  font-size: 1.5rem;
  border: 2px solid transparent;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 0 10px 20px;
  margin-top: 2vh;
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  img{
    max-height: 6vh;
    margin-right: 20px;
  }

  .subtitle{
    color: $white;
    margin: 0;
  }

  &:hover{
    cursor: pointer;
    border: 2px solid $white;
  }
}